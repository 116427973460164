import React, { useState, useEffect } from "react";
import "../../src/saathi-web/saathi-web-style/mainlead.css";
import GeneralService from "../service/generalService"; 
import dateFormat from "dateformat";

const PartnerShowDetails = ({ leadDetail = {}, masterdata = {} }) => {
  const [cityList, setCityList] = useState([]);
  const [stateList, setStateList] = useState([]);

  // Destructuring masterdata
  const { loan_type = [], loan_sub_type = [], profession = [], company_type = [], property_type = [] } = masterdata;

  useEffect(() => {
    const getCityStateList = async () => {      
      try {
        const response = await GeneralService.stateCityList();     
        if (response?.data?.status === 200) {       
          setCityList(response.data.data.city); 
          setStateList(response.data.data.state);         
        }
      } catch (error) {
        console.error("Failed to fetch city and state list", error);
      }
    };
    getCityStateList();    
  }, []); 

  const coApplicants = leadDetail.co_applicant || [];
  const coApplicantCount = coApplicants.length; 

  // Helper function to get label from an array
  const getLabelById = (array, id) => {
    const item = array.find(data => data.id == id);
    return item ? item.label : '';
  };

   // Helper function to get name from an array
   const getNameById = (array, id) => {
    const item = array.find(data => data.id == id);
    return item ? item.name : '';
  };

  const formatCurrency = (amount) => {
    const formattedAmount = new Intl.NumberFormat("en-IN", {
      style: "currency",
      currency: "INR",
    }).format(amount);
    return formattedAmount.replace(/\.00$/, "");
  };

  return (
    <>
      <div className="leftbottomcontainer2">
        <div className="innerleftcont_left">
          <div className="loan_review_heading">
            <div>Loan Details</div>
          </div>
          <div className="loan_review">
            <div>Loan Type</div>
            <div>{getLabelById(loan_type, leadDetail?.loan_type)}</div>
          </div>
          <div className="loan_review">
            <div>Loan Sub-Type</div>
            <div>{getLabelById(loan_sub_type, leadDetail.loan_sub_type)}</div>
          </div>
          <div className="loan_review">
            <div>Required Loan Amount</div>
            <div>
           { leadDetail?.lead_details?.loan_amount ? formatCurrency(leadDetail?.lead_details?.loan_amount) : 'NA'}
            </div>
          </div>
          <div className="loan_review">
            <div>Preferred Tenure</div>
            <div>{leadDetail?.lead_details?.tenure === 0  ? 'NA' : leadDetail?.lead_details?.tenure }</div>
          </div>
        </div>
        <div className="innerleftcont_right">
          <div className="loan_review_heading">
            <div>Customer Details</div>
          </div>
          <div className="loan_review">
            <div>Date Of Birth</div>
            <div>{dateFormat(leadDetail.customer?.dob, 'dd/mm/yyyy')}</div>
          </div>
          <div className="loan_review">
            <div>City</div>
            <div>
  {`${getNameById(cityList, leadDetail?.customer?.cra_city) || ''}${leadDetail?.customer?.cra_city && leadDetail?.customer?.cra_state ? ', ' : ''}${getNameById(stateList, leadDetail?.customer?.cra_state) || ''}`}
</div>

          </div>
          <div className="loan_review">
            <div>PAN NO.</div>
            <div>{leadDetail.customer?.pancard_no}</div>
          </div>
          <div className="loan_review">
            <div>Co-Applicants Added</div>
            <div>{coApplicantCount === 0 ? "NA" : coApplicantCount}</div>
          </div>
        </div>
      </div>
      <div className="leftbottomcontainer2">
        <div className="innerleftcont_left">
          <div className="loan_review_heading">
            <div>Income Details</div>
          </div>
          <div className="loan_review">
            <div>Employment Type</div>
            <div>{getLabelById(profession, leadDetail.lead_details?.profession)}</div>
          </div>
          <div className="loan_review">
            <div>Company Type</div>
            <div>{getLabelById(company_type, leadDetail.lead_details?.company_type)}</div>
          </div>
          <div className="loan_review">
            <div>Current Obligations</div>
            <div>{leadDetail.customer?.obligation?.length || "NA"}</div>
          </div>
        </div>
        <div className="innerleftcont_right">
          <div className="loan_review_heading">
            <div>Property Details</div>
          </div>
          <div className="loan_review">
            <div>Property Decided</div>
            <div>{leadDetail.lead_details?.is_property_identified ? 'Yes' : 'No'}</div>
          </div>
          <div className="loan_review">
            <div>City</div>
           
            <div>
  {`${getNameById(cityList, leadDetail.lead_details?.property_city) || ''}${leadDetail.lead_details?.property_city && leadDetail.lead_details?.property_state ? ', ' : ''}${getNameById(stateList, leadDetail.lead_details?.property_state) || ''}`}
</div>

          </div>
          <div className="loan_review">
            <div>Property Type</div>
            <div>{getLabelById(property_type, leadDetail.lead_details?.property_type)}</div>
          </div>
          <div className="loan_review">
            <div>Estimated Market Value</div>
            <div>{leadDetail.lead_details?.expected_property_value}</div>
          </div>
        </div>
      </div>
    </>
  );
}

export default PartnerShowDetails;
