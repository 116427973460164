import React from "react";



const OtherInformation = ({leadDetail,assignTo}) =>{
    return(
        <>
        <div className="rightbottomcontainer">
                <div className="other_info">Other Information</div>
                <div className="flexrightdiv">
                  <div className="otherinfocolor">Sourced By</div>
                  <div>{leadDetail?.patner_name}</div>
                </div>
                <div className="flexrightdiv">
                  <div className="otherinfocolor">Fulfilment Type</div>
                  <div>{leadDetail?.fulfillment_type} </div>
                </div>
                <div className="flexrightdiv">
                  <div className="otherinfocolor">Current Assignment</div>
                  <div>{assignTo?.filter(data => data.sub_partner_id == leadDetail?.assign_to)?.map(v => v.sub_partner_name)}</div>
                </div>
               {/* <div className="flexrightdiv">
                  <div>Last Assignment</div>
                  <div>NA</div>
                </div>*/}
              </div>
        </>
    );
}



export default OtherInformation;