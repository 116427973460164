import DateFormate from 'dateformat';
import React, { Component } from 'react';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import Select from 'react-select';
import { toast } from 'react-toastify';
import MasterService from '../../../service/MasterService';
import sfaService from '../../../service/SfaService';
import Modal from '../../common/Modal';
import { COUNTRY_CODE, MOBILE_VALIDATION } from './../../../config/constant';
import secureStorage from './../../../config/encrypt';
import Button from './../../elements/Button';
import InputField from './../../elements/Input';
import RadioBox from './../../elements/Radiobox';
import ActivityHistory from './ActivityHistory';
// import ActivityHistory from './ActivityHistory';

const company_type = [
    { id: 1, profession_name: 'Pvt. Ltd.' },
    { id: 2, profession_name: 'LLP' },
    { id: 3, profession_name: 'Proprietorship' },
    { id: 4, profession_name: 'Partnership' },
    { id: 5, profession_name: 'HUF' },
    { id: 6, profession_name: 'Pub. Ltd.' },
    { id: 7, profession_name: 'Others' },
];

const spoc_designation = [
    { id: 9, profession_name: 'Owner' },
    { id: 6, profession_name: 'Co-Owner' },
    { id: 5, profession_name: 'Contact Person' }
];

class BasicDetails extends Component {

    constructor(props) {
        super(props);
        this.state = {
            loading: (props.basic_details) ? false : true,
            basic_details: props.basic_details || {},
            owner_details: props.owner_details || {},
            dealership_list: props.dealership_list,
            // dealer_hash_id:props.dealer_hash_id || null,
            urlprefix: props.urlprefix || null,
            dealership_list_filtered: props.dealership_list[props.basic_details.parent_dealer_type],
            permission: props.permission || {},
            errors: {},
            bussinessErrors: {},
            isReject: false,
            responseMsg: null,
            startDate: new Date(),
            disabledpassword: (props.basic_details && props.basic_details.dealer_id_hash) ? true : false,
            getLanguagelist: props.getLanguagelist,
            getAccountManagerList: props.getAccountManagerList,
            dealerPremiumTypeList: props.dealerPremiumTypeList,
            stockCategoryTypeList: props.stockCategoryTypeList,
            EditFlag: (props.basic_details && props.basic_details.dealer_id_hash) ? true : false,
            previous_basic_details: this.props.basic_details,
            previous_owner_details: this.props.owner_details,
            prevBasicDetailsSet: false,
            prevOwnerDetailsSet: false,
            historyPopup: false,
            isFormFeildDisabled: true,
            getProfessionlist: props.getProfessionlist,
            rmList: {},
            isSaveDisabled: false,
            dealership_dob: new Date(),
            showActivityModel: false,
            activityData: [],
        }

    }

    componentDidMount = () => {
        this.getRmList();
    }

    getRmList = async () => {
        const requestData = {
            role_id: 3
        }
        const rmData = await sfaService.getRMList(requestData);
        this.setState({ rmList: rmData });

    }

    isRejectedPartner = () => {
        this.setState({ isReject: true });
    }

    closeModal = () => {
        this.setState({ historyPopup: false });
    }

    handleHistory = () => {
        this.setState({ historyPopup: true });
    }

    newPasswordEnable = (status) => {
        this.setState({ disabledpassword: status });
    }

    componentWillReceiveProps(nextProps) {
        this.setState({
            getLanguagelist: nextProps.getLanguagelist,
            getProfessionlist: nextProps.getProfessionlist,
            disabledpassword: (nextProps.basic_details && nextProps.basic_details.dealer_id_hash) ? true : false,
            loading: nextProps.loading,
            permission: nextProps.permission,
            basic_details: {
                ...nextProps.basic_details,
                applicant_type_id: nextProps.basic_details && nextProps.basic_details.applicant_type_id ? nextProps.basic_details.applicant_type_id : 1
            },
            // dealer_hash_id: nextProps.dealer_hash_id,
            urlprefix: nextProps.urlprefix,
            dealership_list: nextProps.dealership_list,
            responseMsg: nextProps.responseMsg,
            dealership_list_filtered: nextProps.dealership_list_filtered,
            EditFlag: (nextProps.basic_details && nextProps.basic_details.dealer_id_hash) ? true : false
        });
    }


    validBussinessForm = () => {
        let fields = this.state.basic_details;
        let BussinessformIsValid = true;
        let errors = { ...this.state.errors };
        if (!fields["pan_no"] && (this.state.basic_details.applicant_type === "1" || this.state.basic_details.applicant_type === "2")) {
            errors['pan_no'] = "Enter PAN number"
            BussinessformIsValid = false;
        } else {
            errors['pan_no'] = "";
        }
        if (!fields["aadhar_no"] && this.state.basic_details.applicant_type === "1") {
            errors['aadhar_no'] = "Enter aadhar number"
            BussinessformIsValid = false;
        } else {
            errors['aadhar_no'] = "";
        }
        if (!fields["spoc_name"] && this.state.basic_details.applicant_type === "2") {
            errors['spoc_name'] = "Enter SPOC name"
            BussinessformIsValid = false;
        } else {
            errors['spoc_name'] = "";
        }
        if (!fields["spoc_phone_number"] && this.state.basic_details.applicant_type === "2") {
            errors['spoc_phone_number'] = "Enter SPOC phone number"
            BussinessformIsValid = false;
        } else if (fields["spoc_phone_number"] && !MOBILE_VALIDATION.test(fields["spoc_phone_number"])) {
            BussinessformIsValid = false;
            errors["spoc_phone_number"] = this.props.t('dealerDetails.Basic_Details_master.validation_texts.enter_valid_phone_' + COUNTRY_CODE);
        } else {
            errors['spoc_phone_number'] = "";
        }
        if (!fields["spoc_email"] && this.state.basic_details.applicant_type === "2") {
            errors['spoc_email'] = "Enter SPOC email"
            BussinessformIsValid = false;
        } else {
            errors['spoc_email'] = "";
        }
        if (!fields["spoc_designation"] && this.state.basic_details.applicant_type === "2") {
            errors['spoc_designation'] = "Select SPOC designation"
            BussinessformIsValid = false;
        } else {
            errors['spoc_designation'] = "";
        }


        if (fields["company_type"] == 0 && this.state.basic_details.applicant_type === "2") {
            errors['company_type'] = "Select company type"
            BussinessformIsValid = false;
        } else {
            errors['company_type'] = "";
        }

        this.setState({ errors: errors });
        return BussinessformIsValid;
    }

    validForm = () => {
        let fields = this.state.basic_details;
        //let fields_onwer = this.state.owner_details;
        let formIsValid = true;
        let errors = { ...this.state.errors };
        // if (!fields["organization"]) {
        //     errors['organization'] = this.props.t('dealerDetails.Basic_Details_master.validation_texts.organization');
        //     formIsValid = false;
        // }
        if (!fields["dealership_email"]) {
            errors['dealership_email'] = this.props.t('dealerDetails.Basic_Details_master.validation_texts.enter_valid_email');
            formIsValid = false;
        } else {
            errors['dealership_email'] = "";
        }

        // if (!fields["premium_code"]) {
        //     errors['premium_code'] = this.props.t('dealerDetails.Basic_Details_master.validation_texts.premium_code');
        //     formIsValid = false;
        // }
        if (!fields["dealership_contact"]) {
            errors['dealership_contact'] = this.props.t('dealerDetails.Basic_Details_master.validation_texts.enter_valid_phone_' + COUNTRY_CODE)
            formIsValid = false;
        } else if (!MOBILE_VALIDATION.test(fields["dealership_contact"])) {
            formIsValid = false;
            errors["dealership_contact"] = this.props.t('dealerDetails.Basic_Details_master.validation_texts.enter_valid_phone_' + COUNTRY_CODE);
        } else {
            errors['dealership_contact'] = "";
        }
        // if (!fields_onwer["name"]) {
        //     errors['name'] = this.props.t('dealerDetails.Basic_Details_master.validation_texts.owner_name');
        //     formIsValid = false;
        // }
        if (!fields["first_name"]) {
            errors['first_name'] = "Please enter name";
            formIsValid = false;
        } else {
            errors['first_name'] = "";
        }
        if (!fields["pincode"]) {
            errors['pincode'] = this.props.t('dealerDetails.Basic_Details_master.validation_texts.enter_valid_pincode');
            formIsValid = false;
        } else {
            errors['pincode'] = "";
        }

        // if (!fields["assigned_bm_id"]) {
        //     errors['assigned_bm_id'] = "Please select assgined BM";
        //     formIsValid = false;
        // } else {
        //     errors['assigned_bm_id'] = "";
        // }

        if (!fields["profession_id"]) {
            errors['profession_id'] = "Please select profession";
            formIsValid = false;
        } else {
            errors['profession_id'] = "";
        }


        // if (!fields_onwer["email"]) {
        //     errors['email'] = this.props.t('dealerDetails.Basic_Details_master.validation_texts.enter_valid_email');
        //     formIsValid = false;
        // }
        // if (!fields["dealership_dob"]) {
        //     errors['dealership_dob'] = this.props.t('dealerDetails.Basic_Details_master.validation_texts.this_field_required');
        //     formIsValid = false;
        // }
        if (!fields["address"]) {
            errors['address'] = "Please enter address"
            formIsValid = false;
        } else {
            errors['address'] = ""
        }

        if (!fields["pan_no"]) {
            errors['pan_no'] = "Enter PAN number"
            formIsValid = false;
        } else {
            errors['pan_no'] = "";
        }
        // if (!fields["aadhar_no"] && this.state.basic_details.applicant_type === "1") {
        //     errors['aadhar_no'] = "Enter aadhar number"
        //     formIsValid = false;
        // } else {
        //     errors['aadhar_no'] = "";
        // }
        if (!fields["spoc_name"] && this.state.basic_details.applicant_type === "2") {
            errors['spoc_name'] = "Enter SPOC name"
            formIsValid = false;
        } else {
            errors['spoc_name'] = "";
        }
        if (!fields["spoc_phone_number"] && this.state.basic_details.applicant_type === "2") {
            errors['spoc_phone_number'] = "Enter SPOC phone number"
            formIsValid = false;
        } else if (fields["spoc_phone_number"] && !MOBILE_VALIDATION.test(fields["spoc_phone_number"])) {
            formIsValid = false;
            errors["spoc_phone_number"] = this.props.t('dealerDetails.Basic_Details_master.validation_texts.enter_valid_phone_' + COUNTRY_CODE);
        } else {
            errors['spoc_phone_number'] = "";
        }
        if (!fields["spoc_email"] && this.state.basic_details.applicant_type === "2") {
            errors['spoc_email'] = "Enter SPOC email"
            formIsValid = false;
        } else {
            errors['spoc_email'] = "";
        }
        if (!fields["spoc_designation"] && this.state.basic_details.applicant_type === "2") {
            errors['spoc_designation'] = "Select SPOC designation"
            formIsValid = false;
        } else {
            errors['spoc_designation'] = "";
        }


        if (fields["company_type"] == 0 && this.state.basic_details.applicant_type === "2") {
            errors['company_type'] = "Select company type"
            formIsValid = false;
        } else {
            errors['company_type'] = "";
        }
        this.setState({ errors: errors });
        return formIsValid;
    }

    checkIfDetailChanged(basic_details, previous_basic_details, owner_details, previous_owner_details) {
        let changed_details = {
            dealer_id: basic_details.id,
            dealership_name: "",
            dealership_contact_no: "",
            dealership_email: "",
            owner_name: "",
            owner_email: "",
            owner_contact_no: "",
            is_priority_dealer: "0",
            is_dealer_subscribe_for_dif: "0",
            created_via: "dealer"
        }

        if (previous_owner_details && previous_owner_details.first_name !== basic_details.first_name) {
            changed_details.owner_name = basic_details.first_name;
        }

        if (previous_owner_details && previous_owner_details.dealership_email !== basic_details.dealership_email) {
            changed_details.owner_email = basic_details.dealership_emai;
        }

        if (previous_owner_details && previous_owner_details.dealership_contact !== basic_details.dealership_contact) {
            changed_details.owner_contact_no = basic_details.dealership_contact;
        }

        if (basic_details && previous_basic_details && previous_owner_details.organization !== basic_details.organization) {
            changed_details.dealership_name = basic_details.organization;
        }

        if (basic_details && previous_basic_details && previous_owner_details.dealership_email !== basic_details.dealership_email) {
            changed_details.dealership_email = basic_details.dealership_email;
        }

        if (basic_details && previous_basic_details && previous_owner_details.dealership_contact !== basic_details.dealership_contact) {
            changed_details.dealership_contact_no = basic_details.dealership_contact;
        }

        if (basic_details && previous_basic_details && basic_details.is_dealer_subscribe_for_dif !== previous_basic_details.is_dealer_subscribe_for_dif) {
            changed_details.is_dealer_subscribe_for_dif = basic_details.is_dealer_subscribe_for_dif;
        }

        if (basic_details && previous_basic_details && basic_details.is_priority_dealer !== previous_basic_details.is_priority_dealer) {
            changed_details.is_priority_dealer = basic_details.is_priority_dealer;
        }
        return changed_details;
    }

    checkDetails(details) {
        for (let key in details) {
            if (key !== 'dealer_id' && details[key] && details[key].trim() !== "") {
                return true;
            }
        }
        return false;
    }

    componentDidUpdate(prevProps) {
        if (!this.state.prevBasicDetailsSet && this.props.basic_details && this.props.basic_details.organization) {
            this.setState({ previous_basic_details: JSON.parse(JSON.stringify(this.props.basic_details)), prevBasicDetailsSet: true });
        }

        if (!this.state.prevOwnerDetailsSet && this.props.owner_details && this.props.owner_details.name) {
            this.setState({ previous_owner_details: JSON.parse(JSON.stringify(this.props.owner_details)), prevOwnerDetailsSet: true });
        }
    }

    saveBussinessDetail = async (event) => {
        event.preventDefault();
        if (this.validBussinessForm()) {
            this.setState({ loading: true }, () => {
            });
            if (typeof this.props.onClick === 'function') {
                const basic_detail_data = { ...this.state.basic_details }
                if (!this.state.basic_details.id) {
                    basic_detail_data.source = "Partner Backend";
                }
                this.props.onClick(event, this.state.owner_details, basic_detail_data);
            }
            const saveDetails = this.state.basic_details;
            const requestData = {
                partner_id: saveDetails?.id,
                user_id: saveDetails?.user_id,
                aadhar_no: saveDetails?.aadhar_no,
                pan_no: saveDetails?.pan_no,
                source: "Partner Backend",
                created_by: 0,
            };
            if (saveDetails.applicant_type === "2") {
                requestData.company_type = saveDetails?.company_type;
            }

            if (saveDetails?.applicant_type === "2" || saveDetails?.applicant_type === "3") {
                requestData.is_gst = saveDetails?.is_gst;
                requestData.gst_number = saveDetails?.gst_number;
                requestData.is_msme = saveDetails?.is_msme;
                requestData.msme_number = saveDetails?.msme_number;
                requestData.name = saveDetails?.spoc_name || "";
                requestData.email = saveDetails?.spoc_email || "";
                requestData.mobile = saveDetails?.spoc_phone_number || "";
                requestData.designation = saveDetails?.spoc_designation || "";
            }
            var METHOD = 'post';
            await MasterService.post('/partner/partner/save-bussiness-details', requestData)
                .then(response => {
                    this.setState({ loading: false }, () => {
                    });
                    console.log("response.data", response.data);
                    if (response.data.status == 200 && response.status == 200) {
                        let responseData = response.data;
                        //let dealer_id_hash = responseData.data && responseData.data[0] && responseData.data[0].dealer_id_hash;
                        let other = {};
                        this.setState({ basic_details: this.state.basic_details, owner_details: this.state.owner_details, ...other }, () => {
                            toast.success((response.data.message) ? response.data.message : "Success")
                        })

                    } else {
                        this.setState({ basic_details: this.state.basic_details, owner_details: this.state.owner_details }, () => {
                            toast.error((response.data.message && response.data.message.length > 0) ? response.data.message[0] : "Error");
                        });
                    }
                })
                .catch(error => console.log(error))
            //}
        } else {
            toast.error(this.props.t('dealerDetails.Basic_Details_master.validation_texts.fill_required_fields'));
        }
    }


    saveBasicDetailHistory = async (event) => {
        event.preventDefault();
        if (this.validForm()) {
            if (typeof this.props.onClick === 'function') {
                const basic_detail_data = { ...this.state.basic_details }
                if (!this.state.basic_details.id) {
                    basic_detail_data.source = "Partner Backend";
                }
                this.props.onClick(event, this.state.owner_details, basic_detail_data);
            }
            let changed_details = this.checkIfDetailChanged(this.state.basic_details, this.state.previous_basic_details, this.state.owner_details, this.state.previous_owner_details);
            this.saveBasicDetail(event);
            if (this.state.basic_details && this.state.basic_details.id && this.checkDetails(changed_details)) {
                MasterService.post('/dealer/dealer/save_detail_history', changed_details)
                    .then(response => {

                        this.setState({
                            previous_basic_details: { ...this.state.basic_details },
                            previous_owner_details: { ...this.state.owner_details }
                        });
                    })
                    .catch(error => console.log(error))
            }

        } else {
            toast.error(this.props.t('dealerDetails.Basic_Details_master.validation_texts.fill_required_fields'));
        }
    }


    saveBasicDetail = async (event) => {
        event.preventDefault();
        if (this.validForm()) {
            this.setState({ loading: true }, () => {
            });
            if (typeof this.props.onClick === 'function') {
                const basic_detail_data = { ...this.state.basic_details }
                if (!this.state.basic_details.id) {
                    basic_detail_data.source = "Partner Backend";
                }
                this.props.onClick(event, this.state.owner_details, basic_detail_data);
            }
            //let changed_details = this.checkIfDetailChanged(this.state.basic_details, this.state.previous_basic_details, this.state.owner_details, this.state.previous_owner_details);
            if (this.state.basic_details) {
                const saveDetails = this.state.basic_details;
                const requestData = {
                    name: saveDetails?.first_name,
                    email: saveDetails?.dealership_email,
                    mobile: saveDetails?.dealership_contact,
                    is_pan_verified: 1,
                    //birth_date: saveDetails?.dealership_dob,
                    applicant_type_id: saveDetails?.applicant_type ? Number(saveDetails?.applicant_type) : 1,
                    profession_id: saveDetails?.profession_id,
                    address: saveDetails?.address,
                    pincode: saveDetails?.pincode ? String(saveDetails?.pincode) : "",
                    user_type: "1",
                    term_condition: true,
                    partner_id: saveDetails?.id > 0 ? saveDetails.id : 0,
                    user_id: saveDetails?.user_id > 0 ? saveDetails.user_id : 0,
                    source: "Partner Backend",
                    created_by: 0,
                    pan_no: saveDetails?.pan_no,
                    pan_number: saveDetails?.pan_no,
                    sfa_referral_user_id: saveDetails?.sfa_referral_user_id > 0 ? saveDetails.sfa_referral_user_id :0,
                    //assigned_bm_id: saveDetails?.assigned_bm_id > 0 ? saveDetails.assigned_bm_id : 0,
                    //sfa_referral_user_id: saveDetails?.sfa_referral_user_id > 0 ? saveDetails.sfa_referral_user_id : saveDetails?.assigned_bm_id > 0 ? saveDetails.assigned_bm_id : 0,
                }
                if (saveDetails?.latitude) {
                    requestData.latitude = saveDetails?.latitude ? String(saveDetails?.latitude) : "";
                } 
                if (saveDetails?.longitude) {
                    requestData.longitude = saveDetails?.longitude ? String(saveDetails?.longitude) : "";
                }
                if (saveDetails?.dealership_dob) {
                    let date_of_joining = new Date(saveDetails?.dealership_dob);
                    let join_date = date_of_joining.getFullYear() + '-' + (date_of_joining.getMonth() + 1) + '-' + date_of_joining.getDate();
                    requestData.birth_date = join_date;
                }

                if (saveDetails.aadhar_no) {
                    requestData.aadhar_no = saveDetails?.aadhar_no;
                }
                if (saveDetails.applicant_type === "2") {
                    requestData.company_type = saveDetails?.company_type;
                }

                if (saveDetails.is_aadhar_pan_linked === 1) {
                    requestData.is_aadhar_pan_linked = 1;
                    requestData.is_pan_verified = 1;
                }

                if (saveDetails?.applicant_type === "2" || saveDetails?.applicant_type === "3") {
                    requestData.is_gst = saveDetails?.is_gst;
                    requestData.gst_number = saveDetails?.gst_number;
                    requestData.is_msme = saveDetails?.is_msme;
                    requestData.msme_number = saveDetails?.msme_number;
                    requestData.spoc_name = saveDetails?.spoc_name || "";
                    requestData.spoc_email = saveDetails?.spoc_email || "";
                    requestData.spoc_mobile = saveDetails?.spoc_phone_number || "";
                    requestData.spoc_designation = saveDetails?.spoc_designation || "";
                }

                if (secureStorage.getItem('loginUserInfo')) {
                    var loginResponse = secureStorage.getItem('loginUserInfo');
                    this.props.updateStateIntoRedux('LOGIN_USER', loginResponse);
                    requestData.updated_by = loginResponse?.data?.user_data?.dealer_id || 0;
                }
                console.log("requestData", requestData);
                var METHOD = 'post';
                await MasterService.post('/partner/partner/save-basic-details', requestData)
                    .then(response => {
                        this.setState({ loading: false }, () => {
                        });
                        console.log("response.data", response.data);
                        if (response && response.data.status == 200 && response.status == 200) {
                            let responseData = response.data;
                            let other = {};
                            let dealer_id_hash = responseData.data && responseData.data.response && responseData.data.response.dealer_id_hash;
                            if (!saveDetails?.id) {
                                let other = {};
                                if (dealer_id_hash) {
                                    other = {
                                        urlprefix: '/dealer/' + dealer_id_hash,
                                        dealer_hash_id: dealer_id_hash,
                                    }
                                }
                                else {
                                    return toast.error("Error In Create");
                                }
                                toast.success("Partner created Successfully!")
                                setTimeout(() => {
                                    let url2go = '/dealer/' + dealer_id_hash + '/kyc-info';
                                    window.location.href = url2go;
                                }, 2000)


                            }

                            this.setState({ basic_details: this.state.basic_details, owner_details: this.state.owner_details, ...other }, () => {
                                toast.success((response.data.message) ? response.data.message : "Success")
                            })
                            setTimeout(() => {
                                let url2go = '/dealer/' + dealer_id_hash + '/kyc-info';
                                window.location.href = url2go;
                            }, 1200)
                        } else {
                            this.setState({ basic_details: this.state.basic_details, owner_details: this.state.owner_details }, () => {
                                let errorMsg = (response.data.message && response.data.message.length > 0) ? response.data.message[0]:"Error";
                                let finalError  = errorMsg==="Incorrect Referral Code"?"Please Select onboarding/assigned RM":errorMsg;
                                toast.error(finalError);
                            });
                        }
                    })
                    .catch(error => console.log(error))
            }

        } else {
            toast.error(this.props.t('dealerDetails.Basic_Details_master.validation_texts.fill_required_fields'));
        }
    }


    openActivityModel = async (partnerId, ) => {
        const requestData = {
            partner_id: partnerId ?? 10403,
            // user_id: 387,
            };
        
            MasterService.get("/partner/partner/get-activity-hstory/", {
            ...requestData,
            })
            .then((response) => {
                console.log("response", response.data);
                this.setState({ activityData: response.data, showActivityModel: true });
            })
            .catch((error) => {
                console.log(error);
            });
    }

    closeActivityModel = async () => {
        this.setState({ showActivityModel: false });
    }


    shareAppLink = async (basic_detail, kyc_id) => {
        this.setState({ loading: true }, () => {
        });
        try {
            const confirm = window.confirm("Are you sure want to share the APP Link to partner?");
            if (confirm === true) {
                if (basic_detail) {
                    const saveDetails = basic_detail;
                    const requestData = {
                        partner_id: saveDetails?.id,
                        user_id: saveDetails?.user_id,
                        is_whatsapp: 1,
                        is_sms: 1,
                        mobile: saveDetails?.dealership_contact ? String(saveDetails?.dealership_contact) : "",
                    }
                    if (secureStorage.getItem('loginUserInfo')) {
                        var loginResponse = secureStorage.getItem('loginUserInfo');
                        this.props.updateStateIntoRedux('LOGIN_USER', loginResponse);
                        requestData.updated_by = loginResponse?.data?.user_data?.dealer_id || 0;
                    }
                    await MasterService.post('/partner/partner/share-app-link', requestData)
                        .then(response => {
                            this.setState({ loading: false }, () => {
                            });
                            if (response?.data?.data?.response?.status == true) {
                                this.setState({ basic_details: this.state.basic_details }, () => {
                                    toast.success((response.data.data.message) ? response.data.data.message : "Success")
                                })
                            } else {
                                if (response.data.message.length > 0 && response.data.status === 400) {
                                    toast.error((response.data.message && response.data.message.length > 0) ? response.data.message[0] : "Error");
                                } else {
                                    toast.error((response.data.data.message) ? response.data.data.message : "Error");
                                }
                            }
                        })
                        .catch(error => console.log(error))
                }
            } else {
                this.setState({ loading: false }, () => {
                });
            }

        } catch (err) {
            toast.error(err);
        }
    }

    validatePanNumber = async (basic_detail, kyc_id) => {
        this.setState({ loading: true }, () => {
        });
        try {
            if (basic_detail) {
                const saveDetails = basic_detail;
                const requestData = {
                    partner_id: saveDetails?.id || 0,
                    kyc_master_id: kyc_id,
                    pan_number: saveDetails?.pan_no ? String(saveDetails?.pan_no) : "",
                }
                //console.log("requestData",requestData)
                await MasterService.post('/partner/partner/validate-PAN', requestData)
                    .then(response => {
                        this.state.isSaveDisabled = false;
                        this.setState({ loading: false }, () => {
                        });
                        console.log("responseresponseresponse",response.data.status);
                        if (response.data.status == 200 && response.data.status == 200 && response.data.data.message == "New PAN") {
                            this.pullPanDetails(basic_detail,kyc_id)
                        } else {
                            if (response.data.status === 400) {
                                toast.error(response.data.error.pan_number.message);
                            } else {
                                toast.error((response.data.message) ? response.data.message : "Error");
                            }
                        }
                    })
                    .catch(error => console.log(error))
            }
        } catch (err) {
            toast.error(err);
        }
    }

    pullPanDetails = async (basic_detail, kyc_id) => {
        this.setState({ loading: true }, () => {
        });
        try {
            if (basic_detail) {
                const saveDetails = basic_detail;
                const requestData = {
                    partner_id: saveDetails?.id || 0,
                    user_id: saveDetails?.user_id || 0,
                    kyc_master_id: kyc_id,
                    panNumber: saveDetails?.pan_no ? String(saveDetails?.pan_no) : "",
                }
                //console.log("requestData",requestData)
                await MasterService.post('/partner/partner/validate-pan-number', requestData)
                    .then(response => {
                        this.state.isSaveDisabled = false;
                        this.setState({ loading: false }, () => {
                        });
                        if (response.data.statusCode == 200 && response.data.statusCode == 200) {
                            let other = {};
                            if (response.data?.data) {
                                this.state.isFormFeildDisabled = true;

                                this.state.basic_details.first_name = response.data.data.name || this.state.basic_details.first_name;
                                this.state.basic_details.aadhar_no = response.data.data.maskedAadhaar || this.state.basic_details.aadhar_no;
                                this.state.basic_details.address = response.data.data.address || this.state.basic_details.address;
                                this.state.basic_details.pincode = (response.data.data.pincode !== "None") ? response.data.data.pincode : this.state.basic_details.pincode;
                                this.state.basic_details.dealership_email = response.data.data.email || this.state.basic_details.dealership_email;
                                this.state.basic_details.cityname = response.data.data.city || this.state.basic_details.cityname;
                                this.state.basic_details.state_name = response.data.data.state || this.state.basic_details.state_name;
                                if (response.data.data.email === null || !response.data.data.email) {
                                    this.state.basic_details.dealership_email = "";
                                }
                                if (response.data.data.address === null || !response.data.data.address) {
                                    this.state.basic_details.address = "";
                                }
                                if (response.data.data.city === null || !response.data.data.city) {
                                    this.state.basic_details.cityname = "";
                                }
                                if (response.data.data.state === null || !response.data.data.state) {
                                    this.state.basic_details.state_name = "";
                                }
                                if (response.data.data.pincode === null || !response.data.data.pincode) {
                                    this.state.basic_details.pincode = "";
                                }

                                if (response.data.data.dob) {
                                    this.state.basic_details.dealership_dob = this.convertDateFormat(response.data.data.dob);
                                }
                                if (response.data.data.typeOfHolder === "Company") {
                                    this.state.basic_details.applicant_type = "2";
                                }
                                if (response.data.data.typeOfHolder === "Individual or Person") {
                                    this.state.basic_details.applicant_type = "1";
                                }

                                this.state.basic_details.is_aadhar_pan_linked = response.data.data.aadhaarSeedingStatus === true ? 1 : 0;
                            }
                            this.setState({ basic_details: this.state.basic_details }, () => {
                                toast.success((response.data.message) ? response.data.message : "Success")
                            })
                        } else {
                            this.state.isFormFeildDisabled = false;
                            this.setState({ isFormFeildDisabled: false }, () => {
                            });
                            if (response.data.message.length > 0 && response.data.statusText != "OK") {
                                toast.error((response.data.message && response.data.message.length > 0) ? response.data.message[0] : "Error");
                            } else {
                                toast.error((response.data.message) ? response.data.message : "Error");
                            }
                        }
                    })
                    .catch(error => console.log(error))
            }
        } catch (err) {
            toast.error(err);
        }
    }

    sendEmailVerification = (user_id, dealer_id, email) => {
        const data = {
            "user_id": user_id,
            "dealer_id": dealer_id,
            "email": email
        }
        if (user_id) {
            MasterService.post('dealer/user-manager/verify_partner', data)
                .then(response => {
                    if (response.status == 200) {
                        toast.success(this.props.t('dealerDetails.Basic_Details_master.Verify_Email_Send'));
                    } else {
                        toast.error('Something went wrong! Please try after some time.');
                    }
                })
                .catch(error => console.log(error))

        } else {
            toast.error(this.props.t('dealerDetails.Basic_Details_master.validation_texts.fill_required_fields'));
        }
    }

    handleLeadOptionChange = (sname, oValue) => {
        console.log("sname", sname, oValue)
        let basic_details = this.state.basic_details;
        const errors = this.state.errors;
        this.setState({ basic_details });
        if (sname == 'premium_code') {
            basic_details['premium_code'] = oValue.code;
            delete errors[sname];
        } else {
            if (oValue == null)
                basic_details[sname] = 0;
            else
                basic_details[sname] = (oValue.id) != null ? oValue.id : oValue.target.value;
        }

        this.setState({
            basic_details
        });
    }

    handleRMOptionChange = (sname, oValue) => {
        let basic_details = this.state.basic_details;
        const errors = this.state.errors;
        this.setState({ basic_details });
        basic_details[sname] = (oValue.id) != null ? oValue.id : oValue.target.value;
        console.log(basic_details);
        this.setState({
            basic_details
        });
    }

    handleInputChangeDob = (datefield, event) => {
        const errors = this.state.errors;
        let basic_details = this.state.basic_details;
        if (datefield === 'dealership_dob') {
            // alert(DateFormate(event, 'yyyy-mm-dd'))
            basic_details['dealership_dob'] = new Date(event)
            delete errors['dealership_dob'];
        }
        this.setState({ basic_details, errors }, () => {
        });
    }



    handleCheckboxInputChange = (datefield, e) => {
        const { name, checked } = e.target;
        let basic_details = this.state.basic_details;
        let owner_details = this.state.owner_details;
        const errors = this.state.errors;
        basic_details[name] = checked ? '1' : '0';
        this.setState({ basic_details, errors, owner_details }, () => {
        });
    };

    convertDateFormat(dateString) {
        const [day, month, year] = dateString.split('/');
        const date = new Date(`${year}-${month}-${day}`);
        const formattedYear = date.getFullYear();
        const formattedMonth = String(date.getMonth() + 1).padStart(2, '0');
        const formattedDay = String(date.getDate()).padStart(2, '0');
        return `${formattedYear}-${formattedMonth}-${formattedDay}`;
    }

    handleInputChange = (datefield, event) => {
        let basic_details = this.state.basic_details;
        let owner_details = this.state.owner_details;
        const errors = this.state.errors;
        if (event.target.name == 'pan_no') {
            basic_details['pan_no'] = event.target.value;
            this.state.isSaveDisabled = true;
            event.target.value = event.target.value.toUpperCase();
        }
        if (event.target.name == 'aadhar_no') {
            basic_details['aadhar_no'] = event.target.value;
        }
        if (event.target.name == 'organization') {
            basic_details['dealership_name'] = event.target.value;
        }
        if (event.target.name == 'name' || event.target.name == 'email' ||
            event.target.name == 'mobile' || event.target.name == 'password') {
            owner_details[event.target.name] = event.target.value;
        } else {
            basic_details[event.target.name] = event.target.value;
        }
        if (event.target.value != '') {
            if (event.target.name == 'email' || event.target.name == 'dealership_email') {
                let regexp = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
                if (regexp.test(event.target.value)) {
                    delete errors[event.target.name];
                } else {
                    errors[event.target.name] = this.props.t('dealerDetails.Basic_Details_master.validation_texts.enter_valid_email')
                }
            }
            else if (event.target.name == 'organization') {
                delete errors[event.target.name];
            }
            else if (event.target.name == 'first_name') {
                delete errors[event.target.name];
            }
            else if (event.target.name == 'pan_no') {
                delete errors[event.target.name];
            }
            else if (event.target.name == 'aadhar_no') {
                delete errors[event.target.name];
            }
            else if (event.target.name == 'name' && event.target.value != '') {
                delete errors[event.target.name];
            }
            else if (event.target.name == 'dealership_contact' || event.target.name == 'mobile') {
                if (!MOBILE_VALIDATION.test(event.target.value)) {
                    errors[event.target.name] = this.props.t('dealerDetails.Basic_Details_master.validation_texts.enter_valid_phone_' + COUNTRY_CODE)
                } else {
                    delete errors[event.target.name];
                }

            }

        } else if (event.target.name == 'password') {
            errors[event.target.name] = "";
        } else {
            errors[event.target.name] = this.props.t('dealerDetails.Basic_Details_master.validation_texts.please_enter') + event.target.name.replace("_", " ");
        }
        this.setState({ basic_details, errors, owner_details }, () => {
        });
    }

    handlechecked = (code) => event => {
        //let value = event.target.value;
        //alert(code);
        let value = code;
        let status = true;
        if (event.target.checked) {
            status = true;
        } else {
            status = false;
        }
        let classname = 'dealer_type_' + value;
        let chkbox = document.querySelectorAll('.' + classname).length;

        var basic_details_new2 = { ...this.state.basic_details }
        if (!basic_details_new2.dealer_type_ids) {
            basic_details_new2.dealer_type_ids = [];
        }

        for (let i = 0; i < chkbox; i++) {
            let idname = 'dealer_type_' + value + '_' + i;
            let elements = document.getElementById(idname);

            if (elements) {
                elements.checked = status;
                let chkval = parseInt(elements.value);
                if (status) {
                    if (!basic_details_new2.dealer_type_ids.includes(chkval)) {
                        basic_details_new2.dealer_type_ids.push(chkval);
                    }

                } else {
                    var index = basic_details_new2.dealer_type_ids.indexOf(chkval);
                    if (index !== -1) basic_details_new2.dealer_type_ids.splice(index, 1);
                }
            }
        }
        let is_dealer_subscribe_for_dif = "0"
        if (event.target.name == 'is_dealer_subscribe_for_dif') {
            is_dealer_subscribe_for_dif = event.target.checked ? "1" : "0"
        }
        basic_details_new2.is_dealer_subscribe_for_dif = is_dealer_subscribe_for_dif

        this.setState({ basic_details: basic_details_new2 })

        //this.setState({dealer_type_ids:dealer_type_ids})

    }


    handleCategorychecked = (field) => event => {
        if (field == 'stock_category') {
            let chkval = parseInt(event.target.value);
            let errors = { ...this.state.errors };
            let basic_details = { ...this.state.basic_details };
            if (!basic_details.stock_category) {
                basic_details.stock_category = [];
            }
            if (!basic_details.stock_category.includes(chkval)) {
                // console.log('not');
                basic_details.stock_category.push(chkval);
            } else {
                console.log('yes');
                let index_db = basic_details.stock_category.indexOf(chkval);
                basic_details.stock_category.splice(index_db, 1);
            }
            if (basic_details.stock_category.length == 0)
                errors['stock_category'] = this.props.t('dealerDetails.Basic_Details_master.validation_texts.this_field_required');
            else
                delete errors['stock_category'];

            console.log(basic_details.stock_category, "errors['stock_category']", errors['stock_category']);

            this.setState({ basic_details: basic_details, errors })

        }
    }
    handleApplicationType = (value) => {
        let newFormData = { ...this.state.basic_details };
        newFormData['applicant_type_id'] = value;
        this.setState({ basic_details: newFormData });
        console.log(this.setState.basic_details)
    }

    handledate = (datefield, date) => {

        if (datefield == 'dealer_type_ids') {
            let chkval = parseInt(date.target.value);

            let errors = { ...this.state.errors };
            let basic_details = { ...this.state.basic_details };
            if (!basic_details.dealer_type_ids) {
                basic_details.dealer_type_ids = [];
            }
            if (!basic_details.dealer_type_ids.includes(chkval)) {
                basic_details.dealer_type_ids.push(chkval);
            } else {
                let index_db = basic_details.dealer_type_ids.indexOf(chkval);
                basic_details.dealer_type_ids.splice(index_db, 1);
            }
            if (basic_details.dealer_type_ids.length == 0)
                errors['dealer_type_ids'] = this.props.t('dealerDetails.Basic_Details_master.validation_texts.this_field_required');
            else
                delete errors['dealer_type_ids'];


            this.setState({ basic_details: basic_details, errors })

        } else if (datefield == 'applicant_type_id') {
            let basic_details = { ...this.state.basic_details };
            let dealership_list = { ...this.state.dealership_list };
            let chkval = parseInt(date.target.value);
            let indexval = 0;
            if (chkval == 3) {
                indexval = 1;
            } else if (chkval == 4) {
                indexval = 2;
            }

            basic_details.dealer_type_ids = [];
            let dealership_list_filtered = dealership_list[indexval];
            basic_details.applicant_type = chkval;
            this.setState({ dealership_list_filtered: dealership_list_filtered, basic_details: basic_details }, () => {
            })
        } else if (datefield == 'is_priority_dealer') {
            let basic_details = { ...this.state.basic_details };
            basic_details.is_priority_dealer = date.target.value;
            this.setState({ basic_details }, () => {
            })
        } else {
            var owner_details = { ...this.state.owner_details }
            if (date != null)
                owner_details[datefield] = new Date(date);
            else
                owner_details[datefield] = '';
            this.setState({ owner_details }, () => {
            })
        }
    }

    handleKeyPress = (event) => {
        event.target.value = event.target.value.toUpperCase();
    }



    render() {
        let basicinfo = {};
        let ownerinfo, dealership_list;
        basicinfo = this.state.basic_details;
        ownerinfo = this.state.owner_details;
        dealership_list = this.props.dealership_list;
        //let spoc_details = (basicinfo.spoc_details);
        const nameLabel = (basicinfo.applicant_type === 2 || this.state.basic_details.applicant_type === '2') ? "Company Name" : "Name";
        const dobMaxDate = new Date();
        dobMaxDate.setFullYear(dobMaxDate.getFullYear() - 18);


        let dealership_list_filtered = this.state.dealership_list_filtered;
        let dealershiptype_dealer = dealership_list[0];

        if (dealership_list_filtered) {
            dealershiptype_dealer = dealership_list_filtered;
        }
        else {

            if (basicinfo.parent_dealer_type) {
                if (basicinfo.parent_dealer_type == '3' || basicinfo.parent_dealer_type == 3) {
                    dealershiptype_dealer = dealership_list[1];
                } else if (basicinfo.parent_dealer_type == '4' || basicinfo.parent_dealer_type == 4) {
                    dealershiptype_dealer = dealership_list[2];
                }
            }
        }


        let role, ac_manager_disable_flag = true;
        if (secureStorage.getItem('loginUserInfo')) {
            let loginResponse = secureStorage.getItem('loginUserInfo');
            if (loginResponse && loginResponse.data && loginResponse.data.user_data) {
                role = loginResponse.data.user_data.role;
            }
        }

        if (role == 'admin' || role == "Partner") {
            ac_manager_disable_flag = false;
        }
        let { errors, getLanguagelist, getProfessionlist, rmList } = this.state;
        return (

            <div className='card'>
                {/* {JSON.stringify(basicinfo)} */}



                <Modal isOpen={this.state.isReject} togglePopup={() => this.setState({ isReject: false })}>
                    <div>
                        <h4>Are you sure you want to reject this partner?</h4>
                        <div>
                            <label>Select a reason</label>
                            <Select
                                id="profession_id"
                                options={''}
                                onChange={this.handleLeadOptionChange.bind(this, 'profession_id')}
                                name="profession_id"
                                value={''}
                                getOptionLabel={({ profession_name }) => profession_name}
                                getOptionValue={({ id }) => id}
                            />
                            <div style={{ marginTop: 20 }}>
                                <button className="btn btn-primary pull-right "  >Cancel</button>
                                <button className="btn btn-primary mrg-r10 pull-right ">Reject</button>
                            </div>
                        </div>
                    </div>
                </Modal>
                <div className="card-body">
                    <form className={(this.state.loading) ? 'text-left os-animation animated fadeInDown loading' : 'text-left'} data-os-animation="fadeInDown" data-os-animation-delay="0s" method="post" id="basicdetail_form" onSubmit={this.saveBasicDetailHistory}>
                        <div className="card-heading" style={{ padding: 5 }}>
                            <div style={{ textAlign: "end" }}>
                                <div className='flexcard'>
                                    <div className='basiccard'>
                                        <h1 className="card-title">{this.props.t('dealerDetails.Basic_Details_master.Basic_Details')}
                                            <span>
                                                {
                                                    (basicinfo.gcd_code) ? <span> ({basicinfo.organization} - {basicinfo.gcd_code})</span> : ''
                                                }
                                            </span>
                                        </h1>
                                        <div className='createddate'><div htmlFor="Created_Date" className="">Created Date: {basicinfo.created_date ? DateFormate(basicinfo.created_date, 'dS mmm yyyy h:MM TT') : ""}</div></div>

                                    </div>
                                    {/* <Button btnClass="btn btn-primary mrg-r10" title={this.state.basic_details.pan_no ? this.props.t('dealerDetails.Basic_Details_master.save') : "Create & Share App Link"} type="submit" /> */}
                                    {/* <button className="rejectbtn " onClick={this.isRejectedPartner} >Reject</button> */}
                                    <div style={{ display: "flex", alignItems: "center"}}>
                                        <div
                                            className='btn btn-reset mrg-r10'
                                            style={{ cursor: "pointer", alignItems: "center" }}
                                            onClick={() => this.openActivityModel(this.state.basic_details?.id)}
                                        >
                                            <img style={{ width: 20, marginRight: 10}} src={require(`./../../dealer/assets/images/activity.svg`)} alt="" />
                                            Activity
                                        </div>

                                         <Modal isOpen={this.state.showActivityModel} togglePopup={() => this.closeActivityModel()}>
                                            <div>
                                                <ActivityHistory activityData={this.state?.activityData ?? []}></ActivityHistory>
                                            </div>
                                        </Modal>

                                        <div
                                            className='btn btn-reset mrg-r10'
                                            style={{ cursor: "pointer"}}
                                            onClick={() => this.shareAppLink(this.state.basic_details)}
                                        >
                                            <img style={{ width: 20, marginRight: 10 }} src={require(`./../../dealer/assets/images/share-icon.svg`)} alt="" />
                                            Share Link
                                        </div>
                                        <div>
                                            <Button disabled={this.state.isSaveDisabled} btnClass="btn btn-primary mrg-r10" title={this.props.t('dealerDetails.Basic_Details_master.save')} type="submit" />
                                        </div>
                                    </div>
                                </div>
                                <label></label>
                                {/* {
                                        (basicinfo && basicinfo.id && basicinfo.id > 0) ? <NavLink to={this.props.nextpage} className="btn btn-default">{this.props.t('dealerDetails.Outlet_Details_master.Next')}</NavLink> : ''
                                    } */}
                            </div>
                        </div>
                        <div className="card-heading">
                            <div className='common-details'>Personal details</div>
                            <div className="row">
                                <div className="col-sm-3">
                                    <InputField
                                        maxLength="10"
                                        type="tel" error={(errors.dealership_contact) ? errors.dealership_contact : ''}
                                        name="dealership_contact"
                                        id="dealership_contact_number"
                                        placeholder={this.props.t('dealerDetails.Basic_Details_master.dealership_contact_label')}
                                        value={(basicinfo.dealership_contact) ? basicinfo.dealership_contact : ''}
                                        label="Mobile No"
                                        onChange={this.handleInputChange.bind(this, 'dealership_contact')}
                                        required={true}
                                        disabled={basicinfo.status == '1' ? true : false}
                                    />
                                </div>
                                <div className="col-sm-3">
                                    <InputField
                                        type="text"
                                        error={(errors.pan_no) ? errors.pan_no : ''}
                                        name="pan_no"
                                        id="pan_no"
                                        placeholder='PAN Number'
                                        label='PAN Number'
                                        //onKeyPress={this.handleKeyPress}
                                        value={(this.state.basic_details.pan_no) ? this.state.basic_details.pan_no : ""}
                                        onChange={this.handleInputChange.bind(this, 'pan_no')}
                                        disabled={this.state.basic_details.kyc_status === 1 ? true : false}
                                        required={true}
                                    />
                                </div>

                                <div className="col-sm-2" style={{ marginTop: 28 }}>
                                    <div
                                        className="pandetailsbtn"
                                        disabled={this.state.basic_details.kyc_status === 1 ? true : false}
                                        onClick={() => this.validatePanNumber(this.state.basic_details, 3)}
                                    >Pull PAN Details</div>
                                </div>
                            </div>
                        </div>
                        <div className="card-heading">

                            <div className='partnertype'>
                                <label>Partner Type : </label>
                                <div className="rc-inline" style={{ marginLeft: 20 }}>
                                    <RadioBox
                                        type="radio"
                                        name="applicant_type_id"
                                        id="applicant_type_id"
                                        value="1"
                                        label="Individual"
                                        checked={(this.state.basic_details.applicant_type == 1 || !this.state.basic_details.applicant_type) ? true : false}
                                        onChange={this.handledate.bind(this, "applicant_type_id")}
                                        readOnly={basicinfo.status == '1' || basicinfo.status === '4' ? true : false}
                                        disabled={basicinfo.status == '1' || basicinfo.status === '4' ? true : false}
                                    />
                                    <RadioBox
                                        type="radio"
                                        name="applicant_type_id"
                                        id="applicant_type_id"
                                        value="2"
                                        label="Company"
                                        checked={(this.state.basic_details.applicant_type == 2) ? true : false}
                                        onChange={this.handledate.bind(this, "applicant_type_id")}
                                        readOnly={basicinfo.status === '1' || basicinfo.status === '4' ? true : false}
                                        disabled={basicinfo.status === '1' || basicinfo.status === '4' ? true : false}
                                    />
                                    {/* <RadioBox
                                            type="radio"
                                            name="applicant_type_id"
                                            id="applicant_type_id"
                                            value="3"
                                            label="Propritership"
                                            checked={(this.state.basic_details.applicant_type_id == 3) ? true : false}
                                            onChange={this.handledate.bind(this, "applicant_type_id")}
                                            readonly={basicinfo.status == '1' ? true : false}
                                        /> */}
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-sm-3">
                                    <InputField
                                        type="text"
                                        error={errors.first_name ? errors.first_name : ''}
                                        name="first_name"
                                        id="first_name"
                                        placeholder={nameLabel}
                                        value={this.state.basic_details.first_name || ''}
                                        label={nameLabel}
                                        onChange={this.handleInputChange.bind(this, 'first_name')}
                                        required={true}
                                        disabled={basicinfo.status === '1' || this.state.isFormFeildDisabled == true}
                                    />
                                </div>


                                <div className="col-sm-3">
                                    <div>
                                        <InputField
                                            type="email"
                                            error={(errors.dealership_email) ? errors.dealership_email : ''}
                                            name="dealership_email"
                                            id="dealership_email"
                                            placeholder={this.props.t('dealerDetails.Basic_Details_master.dealership_email_placeholder')}
                                            value={(basicinfo.dealership_email) ? basicinfo.dealership_email : ''}
                                            label={this.props.t('dealerDetails.Basic_Details_master.dealership_email_label')}
                                            onChange={this.handleInputChange.bind(this, 'dealership_email')}
                                            required={true}
                                            disabled={basicinfo.is_email_verified}
                                        />
                                    </div>
                                    {/* <div className='pull-right' style={{ marginTop: -15, marginBottom: 10 }}>
                                        {basicinfo.is_email_verified == "1" || basicinfo.status == "1" || !this.state.basic_details.dealer_id_hash ? "" : <Button btnClass="partnerunsubscribed" title={this.props.t('dealerDetails.Basic_Details_master.Verify_Email')} type="button" onClick={() => this.sendEmailVerification(basicinfo.user_id, basicinfo.id, basicinfo.email)} />}
                                    </div> */}
                                </div>

                                <div className="col-sm-3">
                                    <label>{this.props.t('dealerDetails.Basic_Employee_Details.dealership_dob_label')}</label>
                                    <DatePicker
                                        className="form-control"
                                        selected={basicinfo.dealership_dob ? new Date(basicinfo.dealership_dob) : ""}
                                        onChange={this.handleInputChangeDob.bind(this, 'dealership_dob')}
                                        error={(errors.dealership_dob) ? errors.dealership_dob : ''}
                                        maxDate={dobMaxDate}
                                        showYearDropdown
                                        showMonthDropdown
                                        //required={true}
                                        disabled={basicinfo.status == '1' ? true : false || this.state.isFormFeildDisabled}

                                    />
                                </div>
                                <div className="col-sm-3">
                                    <label>{this.props.t('dealerDetails.Basic_Employee_Details.dealership_professions_label')}<span style={{ color: "red" }}> *</span></label>

                                    <Select
                                        id="profession_id"
                                        options={getProfessionlist}
                                        onChange={this.handleLeadOptionChange.bind(this, 'profession_id')}
                                        name="profession_id"
                                        placeholder={this.props.t('dealerDetails.Basic_Employee_Details.dealership_professions_label')}
                                        value={getProfessionlist && getProfessionlist.filter(({ id }) => id == basicinfo.profession_id)}
                                        getOptionLabel={({ profession_name }) => profession_name}
                                        getOptionValue={({ id }) => id}
                                        isDisabled={basicinfo.status == '1' ? true : false}
                                        required={true}
                                    />
                                    {errors.profession_id &&
                                        <div className="error show" >{errors.profession_id}</div>
                                    }
                                </div>
                            </div>
                            <div className='row'>
                                <div className="col-sm-3">
                                    <InputField
                                        type="text"
                                        error={(errors.address) ? errors.address : ''}
                                        name="address"
                                        value={(basicinfo.address) ? basicinfo.address : ''}
                                        label='Address'
                                        onChange={this.handleInputChange.bind(this, 'address')}
                                        required={true}
                                    // disabled={basicinfo.status == '1' ? true : false || this.state.isFormFeildDisabled}
                                    />
                                </div>
                                <div className="col-sm-3">
                                    <InputField
                                        type="text"
                                        error={(errors.pincode) ? errors.pincode : ''}
                                        name="pincode"
                                        id="pincode"
                                        placeholder='Pincode'
                                        value={(basicinfo.pincode) ? basicinfo.pincode : ''}
                                        label='Pincode'
                                        onChange={this.handleInputChange.bind(this, 'pincode')}
                                        required={true}
                                    //disabled={basicinfo.status == '1' ? true : false || this.state.isFormFeildDisabled}
                                    />
                                </div>
                                {/* <div className="col-sm-3">
                                    <InputField
                                        type="text"
                                        error={(errors.state_name) ? errors.state_name : ''}
                                        name="state_name"
                                        id="state_name"
                                        placeholder='State Name'
                                        value={(basicinfo.state_name) ? basicinfo.state_name : ''}
                                        label="State Name"
                                        onChange={this.handleInputChange.bind(this, 'state_name')}
                                        disabled={true}
                                    />
                                </div> */}
                                {/* <div className="col-sm-3">
                                    <InputField
                                        type="text"
                                        error={(errors.cityname) ? errors.cityname : ''}
                                        name="cityname"
                                        id="cityname"
                                        placeholder='cityname'
                                        value={(basicinfo.cityname) ? basicinfo.cityname : ''}
                                        label="City Name"
                                        onChange={this.handleInputChange.bind(this, 'cityname')}
                                        disabled={true}
                                    />
                                </div> */}
                            </div>

                            {/* {getLanguagelist && getLanguagelist.length > 1 ?
                                <div className="col-sm-3">
                                    <label>{this.props.t('dealerDetails.Basic_Employee_Details.language')}</label>
                                    <Select
                                        id="lang_id"
                                        options={getLanguagelist}
                                        onChange={this.handleLeadOptionChange.bind(this, 'lang_id')}
                                        name="lang_id"
                                        placeholder={this.props.t('dealerDetails.Basic_Employee_Details.language')}
                                        value={getLanguagelist.filter(({ id }) => id === basicinfo.lang_id)}
                                        getOptionLabel={({ name }) => name}
                                        getOptionValue={({ id }) => id}
                                    />
                                </div>
                                : ''}    */}

                        </div>
                        <div className="card-heading">

                            <div className='common-details'>Relationship details</div>
                            <div className="row">
                                <div className="col-sm-3">
                                    <label>Onboarding BM</label>
                                    <Select
                                        id="sfa_referral_user_id"
                                        options={rmList}
                                        onChange={this.handleLeadOptionChange.bind(this, 'sfa_referral_user_id')}
                                        name="sfa_referral_user_id"
                                        placeholder="Select Onbording BM"
                                        value={basicinfo.sfa_referral_user_id && rmList && rmList.length > 0 ? rmList.find(({ id }) => id === basicinfo.sfa_referral_user_id) : ""}
                                        getOptionLabel={({ profession_name }) => profession_name}
                                        getOptionValue={({ id }) => id}
                                        label="Onbording BM"
                                        isDisabled={basicinfo?.id > 0 && basicinfo?.sfa_referral_user_id > 0 ? true : false}
                                    />
                                </div>
                                {/* <div className="col-sm-3">
                                    <label>Assigned BM <span style={{ color: "red" }}>*</span></label>
                                    <Select
                                        id="assigned_bm_id"
                                        options={rmList}
                                        error={(errors.assigned_bm_id) ? errors.assigned_bm_id : ''}
                                        placeholder="Select Assigned BM"
                                        onChange={this.handleLeadOptionChange.bind(this, 'assigned_bm_id')}
                                        name="assigned_bm_id"
                                        value={rmList && rmList.length > 0 && rmList.filter(({ id }) => id === basicinfo.assigned_bm_id)}
                                        getOptionLabel={({ profession_name }) => profession_name}
                                        getOptionValue={({ id }) => id}
                                        required={true}

                                    />
                                    {errors.assigned_bm_id &&
                                        <div className="error show" >{errors.assigned_bm_id}</div>
                                    }
                                </div> */}

                                {/* <div className="col-sm-3">
                                    <InputField
                                        maxLength="10"
                                        type="tel" error={(errors.assigned_bm) ? errors.assigned_bm : ''}
                                        name="assigned_bm"
                                        id="dealership_contact_number"
                                        placeholder="Assigned BM"
                                        value={(basicinfo.assigned_bm) ? basicinfo.assigned_bm : ''}
                                        label="Assigned BM"
                                        onChange={this.handleInputChange.bind(this, 'assigned_bm')}
                                        required={true}
                                    />
                                    
                                </div> */}

                                {/* <div className="col-sm-3">
                                    <div>
                                        <InputField
                                            type="email"
                                            error={(errors.bm_email) ? errors.bm_email : ''}
                                            name="bm_email"
                                            id="bm_email"
                                            placeholder="BM Email"
                                            value={(basicinfo.bm_email) ? basicinfo.bm_email : ''}
                                            label="BM Email"
                                            onChange={this.handleInputChange.bind(this, 'bm_email')}
                                            required={true}
                                        />
                                    </div>

                                </div>
                                <div className="col-sm-3">
                                    <div>
                                        <InputField
                                            type="email"
                                            error={(errors.bm_phone_number) ? errors.bm_phone_number : ''}
                                            name="bm_phone_number"
                                            id="bm_phone_number"
                                            placeholder="BM Phone Number"
                                            value={(basicinfo.bm_phone_number) ? basicinfo.bm_phone_number : ''}
                                            label="BM Phone Number"
                                            onChange={this.handleInputChange.bind(this, 'bm_phone_number')}
                                            required={true}
                                        />
                                    </div>
                                </div> */}





                            </div>
                        </div>
                        <div className="card-heading">
                            {/* <div style={{ padding: 15 }}> */}
                            <div className='common-details' style={{ marginTop: 18 }}>Business details</div>
                            <div className="row" style={(this.state.permission.dealerTypeViewOnly == true) ? { pointerEvents: 'none' } : null}>
                            </div>
                            {(basicinfo.applicant_type === 1 || this.state.basic_details.applicant_type === '1' || !this.state.basic_details.applicant_type) && (
                                <>
                                    <div className='row'>
                                        <div className="col-sm-3">
                                            <InputField
                                                type="string"
                                                error={(errors.aadhar_no) ? errors.aadhar_no : ''}
                                                name="aadhar_no"
                                                id="aadhar_no"
                                                placeholder='Aadhar Number'
                                                label='Aadhar Number'
                                                value={(this.state.basic_details.aadhar_no) ? this.state.basic_details.aadhar_no : ""}
                                                onChange={(event) => this.handleInputChange('aadhar_no', event)}
                                                //required={true}
                                                disabled={true}
                                            />
                                        </div>
                                    </div>
                                </>
                            )}

                            {(basicinfo.applicant_type === 2 || this.state.basic_details.applicant_type === '2') && (
                                <>

                                    <div className='row'>

                                        <div className="col-sm-3">
                                            <label>Company Type</label>
                                            <Select
                                                id="company_type"
                                                onChange={this.handleLeadOptionChange.bind(this, 'company_type')}
                                                options={company_type}
                                                name="company_type"
                                                placeholder="Select Company Type"
                                                value={company_type.find(({ id }) => id === basicinfo.company_type)}
                                                getOptionLabel={({ profession_name }) => profession_name}
                                                getOptionValue={({ id }) => id}
                                                label="Company Name"
                                                required={true}
                                            />
                                            {errors.company_type &&
                                                <div className="error show" >{errors.company_type}</div>
                                            }
                                        </div>
                                        {/* <div className="col-sm-3">
                                            <InputField
                                                type="text"
                                                error={(errors.pan_no) ? errors.pan_no : ''}
                                                name="pan_no"
                                                id="pan_no"
                                                placeholder='PAN Number'
                                                label='PAN Number'
                                                value={(this.state.basic_details.pan_no) ? this.state.basic_details.pan_no : ""}
                                                onChange={(event) => this.handleInputChange('pan_no', event)}
                                                required={true}
                                            />
                                        </div> */}
                                        <div className="col-sm-3">
                                            <div className='basiccheckbox'>
                                                {/* <input type='checkbox' /> */}
                                                <input
                                                    type="checkbox"
                                                    name="is_gst"
                                                    id="is_gst"
                                                    disabled={basicinfo.kyc_status === 1 ? true : false}
                                                    checked={basicinfo.is_gst === '1'} // Check if basicinfo.is_gst equals '1'
                                                    //onChange={this.ChangeCheckbox(idx, 'showroom')}
                                                    onChange={(event) => this.handleCheckboxInputChange("is_gst", event)}
                                                    value="1"
                                                />
                                                <label>GST Registered</label>
                                            </div>
                                            <InputField
                                                type="text"
                                                error={(errors.gst_registered) ? errors.gst_registered : ''}
                                                name="gst_number"
                                                id="gst_number"
                                                placeholder='GST Number'
                                                onChange={this.handleInputChange.bind(this, 'gst_number')}
                                                required={true}
                                                value={(basicinfo.gst_number) ? basicinfo.gst_number : ''}
                                                is_label_required={true}
                                                disabled={basicinfo.kyc_status === 1 ? true : false}
                                                className="hidden"
                                            />
                                        </div>

                                        <div className="col-sm-3">
                                            <div className='basiccheckbox'>
                                                <input
                                                    type="checkbox"
                                                    name="is_msme"
                                                    id="is_msme"
                                                    checked={basicinfo.is_msme === '1'} // Check if basicinfo.is_gst equals '1'
                                                    //onChange={this.ChangeCheckbox(idx, 'showroom')}
                                                    onChange={(event) => this.handleCheckboxInputChange("is_msme", event)}
                                                    disabled={basicinfo.kyc_status === 1 ? true : false}
                                                    value="1"
                                                />
                                                <label>MSME Registered</label>
                                            </div>
                                            <InputField
                                                type="text"
                                                error={(errors.msme_number) ? errors.msme_number : ''}
                                                name="msme_number"
                                                id="msme_number"
                                                placeholder='MSME Number'
                                                onChange={this.handleInputChange.bind(this, 'msme_number')}
                                                required={true}
                                                value={(basicinfo.msme_number) ? basicinfo.msme_number : ''}
                                                disabled={basicinfo.kyc_status === 1 ? true : false}
                                                is_label_required={true}
                                            />
                                        </div>
                                    </div>
                                    <div className='row'>
                                        <div className="col-sm-3">
                                            <InputField
                                                type="text"
                                                error={(errors.spoc_name) ? errors.spoc_name : ''}
                                                name="spoc_name"
                                                id="spoc_name"
                                                placeholder='Company SPOC Name'
                                                label='Company SPOC Name'
                                                value={(basicinfo.spoc_name) ? basicinfo.spoc_name : ''}
                                                onChange={this.handleInputChange.bind(this, 'spoc_name')}
                                                required={true}
                                            />
                                        </div>
                                        <div className="col-sm-3">
                                            <label>SPOC Designation<span style={{ color: "red" }}>*</span></label>
                                            <Select
                                                id="spoc_designation"
                                                onChange={this.handleLeadOptionChange.bind(this, 'spoc_designation')}
                                                options={spoc_designation}
                                                name="spoc_designation"
                                                placeholder="Select SPOC Designation"
                                                value={basicinfo.spoc_designation ? spoc_designation.find(({ id }) => id === basicinfo.spoc_designation) : ""}
                                                getOptionLabel={({ profession_name }) => profession_name}
                                                getOptionValue={({ id }) => id}
                                                label="SPOC Designation"
                                            //required={true}
                                            />
                                            {errors.spoc_designation &&
                                                <div className="error show" >{errors.spoc_designation}</div>
                                            }
                                        </div>
                                        <div className="col-sm-3">
                                            <InputField
                                                type="text"
                                                error={(errors.spoc_phone_number) ? errors.spoc_phone_number : ''}
                                                name="spoc_phone_number"
                                                id="spoc_phone_number"
                                                placeholder='SPOC Phone Number'
                                                label='SPOC Phone Number'
                                                maxLength={10}
                                                value={(basicinfo.spoc_phone_number) ? basicinfo.spoc_phone_number : ''}
                                                onChange={this.handleInputChange.bind(this, 'spoc_phone_number')}
                                                required={true}
                                            />
                                        </div>
                                        <div className="col-sm-3">
                                            <InputField
                                                type="text"
                                                error={(errors.spoc_email) ? errors.spoc_email : ''}
                                                name="spoc_email"
                                                id="spoc_email"
                                                placeholder='SPOC E-Mail'
                                                label='SPOC E-Mail'
                                                value={(basicinfo.spoc_email) ? basicinfo.spoc_email : ''}
                                                onChange={this.handleInputChange.bind(this, 'spoc_email')}
                                                required={true}
                                            />
                                        </div>

                                    </div>
                                </>
                            )}
                        </div>
                    </form>



                    {/* <form className={(this.state.loading) ? 'text-left os-animation animated fadeInDown loading' : 'text-left os-animation animated fadeInDown'} data-os-animation="fadeInDown" data-os-animation-delay="0s" method="post" id="bussinessdetail_form" onSubmit={this.saveBussinessDetail}>
                        

                    </form> */}
                    {/* <div className="row">
                        <div className="col-sm-10">
                            <label htmlFor="Created_Date" className="">Created Date:{basicinfo.created_date ? DateFormate(basicinfo.created_date, 'dS mmm yyyy h:MM TT') : ""}</label>
                        </div>

                    </div> */}

                </div>

                {/* {this.state.historyPopup === false ? null : <ModalPopup id="view_history" className="modal ViewHistory data-table" title={'View History'} modalClose={this.closeModal}>
                    {basicinfo && basicinfo.id && <ViewHistory histoy_dealer_id={basicinfo.id} />}
                </ModalPopup>} */}
            </div>

        )
    }
}

const mapStateToProps = state => {
    return {
        auth_token: state.authToken,
        user_loggedin: state.userLoggedIn,
        dealer_id: state.dealer_id
    };
}

const mapDispatchToProps = dispatch => {
    return {
        updateStateIntoRedux: (actionType, stateData) => dispatch({ type: actionType, componentState: stateData })
    }
}

export default withTranslation('dealers')(connect(mapStateToProps, mapDispatchToProps)(BasicDetails));