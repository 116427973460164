import React, { useEffect, useState, useMemo } from "react";
import "../../../src/saathi-web/saathi-web-style/mainlead.css";
import FormFields from "../../../src/saathi-web/MyLeads/formFields/coApplicantDetailsFormFields.json";
import dateFormat from "dateformat";
import GeneralService from "../../service/generalService";
import {
  SAVE_CUST_DETAILS,
  GET_DETAILS_BY_PANCARD,
} from "../../queries/sangam/customer.gql";
import {
  executeGraphQLQueryFinex,
  executeGraphQLMutationFinex,
} from "../../common/executeGraphQLQuery";
import { toast } from "react-toastify";
import Formsy from "formsy-react";
import FormsyInputField from "../../view/elements/FormsyInputField";
import FormsySelect from "../../view/elements/FormsySelect";
import FormsyDatePicker from "../../view/elements/FormsyDatePicker";
import { useParams, useHistory } from "react-router-dom";
import crypto from "../../config/crypto";

const MaskData = require("maskdata");
const FORM_FIELDS = FormFields["BASIC_DETAILS"];
const MAX_CO_APPLICANT = 4;

const maskOptions = {
  maskWith: "*",
  unmaskedStartDigits: 0,
  unmaskedEndDigits: 4,
  maskSpace: false,
};

const DOB_MAX_DATE = new Date().setFullYear(new Date().getFullYear() - 18);
const DOB_MIN_DATE = new Date().setFullYear(new Date().getFullYear() - 76);
const GENDER_OPTIONS = [
  { id: "1", label: "Male", value: "male" },
  { id: "2", label: "Female", value: "female" },
];

const CoApplicantDetails = ({
  leadDetail,
  masterdata,
  masterStatusData,
  setActiveTabFromChild,
  activeCoApplicant = "coApplicant_0",
}) => {
  const [basicDetails, setBasicDetails] = useState({});
  const [allowSubmit, setAllowSubmit] = useState(false);
  const [isFetchAadharVisible, setIsFetchAadharVisible] = useState(false);
  const [isFormTouch, setTouch] = useState({});
  const [activeTab, setActiveTab] = useState(activeCoApplicant);
  const [cityList, setCityList] = useState([]);
  const [stateList, setStateList] = useState([]);
  let history = useHistory();
  let user_id = 1;

  let activetabIndex = activeTab.split("_");
  activetabIndex = activetabIndex[1];

  const setStateAndCityList = async () => {
    try {
      const response = await GeneralService.stateCityList();
      if (response?.data?.status === 200) {
        const result = { ...response.data.data };
        if (result.city && result.city.length) {
          result.city = result.city.map((data) => ({
            ...data,
            value: data.id,
            label: data.name,
          }));
        }

        if (result.state && result.state.length) {
          result.state = result.state.map((data) => ({
            ...data,
            value: data.id,
            label: data.name,
          }));
        }

        setCityList(result.city);
        setStateList(result.state);
      }
    } catch (error) {
      console.log(error.message);
      toast.error("Failed to fecth state and city list");
    }
  };

  useEffect(() => {
    if (leadDetail) {
      let co_applicatndata = leadDetail?.co_applicant || [];
      if (co_applicatndata.length > 0) {
        const newCoApplicants = co_applicatndata.map((data) => {
          let newAppObject = {};
          newAppObject.id = data.id || 0;
          newAppObject.ca_annual_income = data.ca_annual_income || "";
          if (data.ca_profession)
            newAppObject.ca_profession = data.ca_profession || "";
          if (data.ca_salary_credit_mode)
            newAppObject.ca_salary_credit_mode =
              data.ca_salary_credit_mode || "";
          newAppObject.ca_company_name = data.ca_company_name || "";
          newAppObject.ca_company_type = data.ca_company_type || "";
          newAppObject.ca_loan_amount = data.ca_loan_amount || "";
          newAppObject.ca_tenure = +data.ca_tenure || 0;

          newAppObject.ca_first_name = data.ca_first_name || "";
          newAppObject.ca_last_name = data.ca_last_name || "";
          newAppObject.ca_gender = data.ca_gender || "";
          newAppObject.relationship_with_customer =
            data.relationship_with_customer || "";
          newAppObject.ca_mobile = data.ca_mobile || "";
          newAppObject.masked_ca_mobile = MaskData.maskCard(
            data.ca_mobile,
            maskOptions
          );
          newAppObject.ca_email = data.ca_email || "";
          newAppObject.ca_marital_status = data.ca_marital_status || "";
          newAppObject.ca_dob = data.ca_dob || "";
          newAppObject.ca_aadhar_no = data.ca_aadhar_no || "";
          let aadhar_no =
            data && data.ca_aadhar_no
              ? data?.ca_aadhar_no?.match(/.{1,4}/g).join(" ")
              : "";
          newAppObject.masked_ca_aadhar_no = MaskData.maskCard(
            aadhar_no,
            maskOptions
          );
          newAppObject.aadhar_touch = 1;
          newAppObject.ca_pancard_no = data.ca_pancard_no || "";
          newAppObject.ca_annual_income = data.ca_annual_income || "";
          newAppObject.ca_mother_name = data.ca_mother_name || "";
          newAppObject.ca_qualification = data.ca_qualification || "";

          newAppObject.ca_cra_address1 = data.ca_cra_address1 || "";
          newAppObject.ca_cra_address2 = data.ca_cra_address2 || "";
          newAppObject.ca_cra_state = data.ca_cra_state || "";
          newAppObject.ca_cra_city = data.ca_cra_city || "";
          newAppObject.ca_cra_pincode = data.ca_cra_pincode || "";
          newAppObject.ca_type = data.ca_type || "";
          newAppObject.same_as_cus_addr = data.same_as_cus_addr || "0";
          // newAppObject.same_as_cus_addr = data.same_as_cus_addr || '0';

          newAppObject.isFetchAadharVisible = false;

          if (
            newAppObject.ca_pancard_no !== null &&
            newAppObject.ca_pancard_no !== "" &&
            (newAppObject.ca_aadhar_no === null ||
              newAppObject.ca_aadhar_no === "")
          ) {
            newAppObject.isFetchAadharVisible = true;
          }

          return newAppObject;
        });
        if (newCoApplicants.length - 1 < activeCoApplicant.split("_")[1]) {
          newCoApplicants.push({
            ca_first_name: "",
            ca_last_name: "",
            ca_gender: "",
            relationship_with_customer: "",
            ca_mobile: "",
            ca_email: "",
            ca_dob: "",
            ca_aadhar_no: "",
            ca_pancard_no: "",
            ca_annual_income: "",
          });
        }
        setBasicDetails(newCoApplicants);
      }
      if (co_applicatndata.length === 0) newCoapplicant();
      setStateAndCityList();
    }
  }, [leadDetail]);

  const newCoapplicant = () => {
    const newCoApplicants = [
      {
        ca_first_name: "",
        ca_last_name: "",
        ca_gender: "",
        relationship_with_customer: "",
        ca_mobile: "",
        ca_email: "",
        ca_dob: "",
        ca_aadhar_no: "",
        ca_pancard_no: "",
        ca_annual_income: "",
        ca_mother_name: "",
        ca_qualification: "",
        ca_cra_address1: "",
        ca_cra_address2: "",
        ca_cra_state: "",
        ca_cra_city: "",
        ca_cra_pincode: "",
        ca_type: "",
        same_as_cus_addr: "0",
        aadhar_touch: 1,
        masked_ca_mobile: "",
      },
    ];
    setBasicDetails(newCoApplicants);
  };

  useEffect(() => {
    const getCityStateList = async () => {
      try {
        const response = await GeneralService.stateCityList();
        if (response?.data?.status === 200) {
          let result = { ...response.data.data };

          if (result.city && result.city.length) {
            result.city = result.city.map((data) => ({
              ...data,
              value: data.id,
              label: data.name,
            }));
          }

          if (result.state && result.state.length) {
            result.state = result.state.map((data) => ({
              ...data,
              value: data.id,
              label: data.name,
            }));
          }

          setCityList(result.city);
          setStateList(result.state);
        }
      } catch (error) {
        console.error("Failed to fetch city and state list", error);
      }
    };
    getCityStateList();
  }, []);

  let ALL_OPTIONS = useMemo(() => {
    let options = masterdata ? { ...masterdata } : {};
    options["qualification"] = options["qualification"]?.map((obj) => ({
      ...obj,
      value: obj.id,
    }));
    options["marital_status"] = options["marital_status"]?.map((obj) => ({
      ...obj,
      value: obj.id,
    }));
    options["gender"] = GENDER_OPTIONS;
    options["state_list"] = stateList;
    options["city"] = cityList;
    options["ca_type"] = [
      { label: "Financing", value: "financing" },
      { label: "Non Financing", value: "non_financing" },
    ];

    return options;
  }, [masterdata, stateList]);

  const setCityByStateChange = (state) => {
    let options = ALL_OPTIONS ? { ...ALL_OPTIONS } : {};
    if (options?.city && options?.city?.length && state) {
      let record = options.city
        .filter((ob) => ob.state_id == state)
        .map((data) => {
          data.value = data.id;
          data.label = data.label;
          return data;
        });

      ALL_OPTIONS["city_list"] = record;
    }
  };

  useEffect(() => {
    let activetabIndex = activeTab.split("_")[1];
    setCityByStateChange(basicDetails[activetabIndex]?.ca_cra_state);
  }, [basicDetails[activeTab.split("_")[1]]?.ca_cra_state]);

  const addMoreFields = () => {
    const newCoApplicants = [
      ...basicDetails,
      {
        ca_first_name: "",
        ca_last_name: "",
        ca_gender: "",
        relationship_with_customer: "",
        ca_mobile: "",
        ca_email: "",
        ca_dob: "",
        ca_aadhar_no: "",
        ca_pancard_no: "",
        ca_annual_income: "",
      },
    ];
    if (basicDetails[basicDetails.length - 1].id) {
      setBasicDetails(newCoApplicants);
      setActiveTab(`coApplicant_${newCoApplicants.length - 1}`);
    }
  };

  const handleInputChange = (event) => {
    let { id, value } = event.target;
    setTouch({
      ...isFormTouch,
      [id]: true,
    });
    if (id) {
      let activetabIndex = activeTab.split("_");
      activetabIndex = activetabIndex[1];
      const updatedBasicDetails = [...basicDetails];
      if (id === "ca_pancard_no") {
        let inputVal = value.toUpperCase();
        updatedBasicDetails[activetabIndex][id] = inputVal;
        const isValidPan = /^[A-Z]{3}[CHFATBLJGP][A-Z]\d{4}[A-Z]$/.test(
          inputVal
        );
        if (
          isValidPan &&
          (updatedBasicDetails[activetabIndex]["ca_aadhar_no"] === "" ||
            updatedBasicDetails[activetabIndex]["ca_aadhar_no"] === null ||
            updatedBasicDetails[activetabIndex]["ca_aadhar_no"] === undefined)
        ) {
          updatedBasicDetails[activetabIndex]["isFetchAadharVisible"] = true;
        } else {
          updatedBasicDetails[activetabIndex]["isFetchAadharVisible"] = false;
        }
      } else {
        if (id == "ca_cra_pincode" && value.length == 6) {
          setCityStateByPin(value);
        }
        let finalValue = value;
        if (id == "ca_aadhar_no") {
          finalValue = finalValue.replace(/\W/gi, "").replace(/(.{4})/g, "$1 ");
          finalValue = /^[0-9\sX]+$/.test(finalValue)
            ? finalValue
            : "XXXX XXXX";
          updatedBasicDetails[activetabIndex].aadhar_touch = 0;
        }
        updatedBasicDetails[activetabIndex][id] = finalValue.trim();
      }

      setBasicDetails(updatedBasicDetails);
    }
  };

  const setCityStateByPin = async (pincode) => {
    try {
      let response = await GeneralService.stateCityByPincode(pincode);
      if (response?.data?.data?.length) {
        let item = response.data.data[0];
        let newArray = basicDetails;
        newArray[activetabIndex].ca_cra_pincode = pincode;
        newArray[activetabIndex].ca_cra_state = item?.state_id;
        newArray[activetabIndex].ca_cra_city = item?.city_id;
        setCityByStateChange(item?.state_id);
        setBasicDetails(newArray);
      }
    } catch (error) {
      console.error("Error :", error);
    }
  };

  const handleDateChange = (date, field_name) => {
    if (date && field_name) {
      let activetabIndex = activeTab.split("_");
      activetabIndex = activetabIndex[1];
      const updatedBasicDetails = [...basicDetails];
      updatedBasicDetails[activetabIndex][field_name] = date;
      setBasicDetails(updatedBasicDetails);
    }
  };

  const handleSelectChange = (data, field_name) => {
    if (data && field_name) {
      if (field_name.includes("ca_cra_state")) {
        setCityByStateChange(data.value);
      }
      let activetabIndex = activeTab.split("_");
      activetabIndex = activetabIndex[1];
      const updatedBasicDetails = [...basicDetails];
      updatedBasicDetails[activetabIndex][field_name] = data.value;
      setBasicDetails(updatedBasicDetails);
    }
  };

  const handleSubmit = () => {
    let lead_id = leadDetail?.id || "";
    let newbasicDetails = basicDetails.map((ob) => {
      return {
        id: ob.id,
        ca_annual_income: ob.ca_annual_income,
        ca_first_name: ob.ca_first_name,
        ca_last_name: ob.ca_last_name,
        ...(ob.relationship_with_customer && {
          relationship_with_customer: ob.relationship_with_customer,
        }),
        ca_mobile: ob.ca_mobile,
        ca_email: ob.ca_email ? ob.ca_email : null,
        ...(ob.ca_marital_status && {
          ca_marital_status: ob.ca_marital_status,
        }),
        ...(ob.ca_gender && { ca_gender: ob.ca_gender }),
        ...(ob.ca_dob && { ca_dob: ob.ca_dob }),
        ca_type: ob.ca_type,
        ca_aadhar_no: ob.ca_aadhar_no.trim().replace(/\s/g, "") || "",
        ca_pancard_no: ob.ca_pancard_no,
        ca_mother_name: ob.ca_mother_name,
        ca_qualification: ob.ca_qualification,
        ca_cra_pincode: ob.ca_cra_pincode,
        ca_cra_address1: ob.ca_cra_address1,
        ca_cra_address2: ob.ca_cra_address2,
        ...(+ob.ca_cra_state && { ca_cra_state: +ob.ca_cra_state }),
        ...(+ob.ca_cra_city && { ca_cra_city: +ob.ca_cra_city }),
        ca_cra_state: +ob.ca_cra_state,
        ca_cra_city: +ob.ca_cra_city,
        same_as_cus_addr: ob.same_as_cus_addr,
      };
    });
    let activetabIndex = activeTab.split("_");
    activetabIndex = activetabIndex[1];
    newbasicDetails = newbasicDetails[activetabIndex]
      ? [newbasicDetails[activetabIndex]]
      : newbasicDetails;

    let mutation = SAVE_CUST_DETAILS(user_id),
      variables = {
        api_called_by: "saathi_web",
        partner_id: leadDetail.partner_id,
        LeadAdditionalInput: {
          lead_id: lead_id,
          co_applicant: newbasicDetails,
        },
      };

    executeGraphQLMutationFinex(mutation, variables)
      .then((resp) => {
        let response = resp?.data?.LeadAdditionalInput || null;
        if (response && !response.success) {
          throw new Error(response.error || "Something went wrong");
        }

        toast.success("Success");
        setTouch({});
      })
      .catch((err) => {
        toast.error(err.message);
      });
  };

  let BasicDetailsForm = useMemo(() => {
    return FORM_FIELDS.map((field) => {
      if (field.name === "ca_dob") {
        field["maxDate"] = DOB_MAX_DATE;
        field["minDate"] = DOB_MIN_DATE;
      }
      return field;
    });
  }, []);

  const handleFetchAadhar = async (event) => {
    try {
      const { id } = event.target;
      const aadharField = document.getElementById(`ca_aadhar_no`);
      const pan_card = basicDetails[id]["ca_pancard_no"];

      executeGraphQLQueryFinex(GET_DETAILS_BY_PANCARD(pan_card))
        .then((result) => {
          if (result.data) {
            const jsonResp = JSON.parse(result.data?.get_details_by_pancard);
            if (jsonResp.status === 200) {
              if (jsonResp.data?.response?.maskedAadhaar) {
                const finalArr = basicDetails.map((obj, index) => {
                  if (index == id) {
                    return {
                      ...obj,
                      ca_aadhar_no: jsonResp.data?.response?.maskedAadhaar,
                      masked_ca_aadhar_no:
                        jsonResp.data?.response?.maskedAadhaar,
                      isFetchAadharVisible: false,
                    };
                  } else {
                    return obj;
                  }
                });
                setBasicDetails(finalArr);
                aadharField.readOnly = true;
              } else {
                const finalArr = basicDetails.map((obj, index) => {
                  if (index == id) {
                    return {
                      ...obj,
                      ca_aadhar_no: `XXXXXXXX`,
                      masked_ca_aadhar_no: `XXXXXXXX`,
                      isFetchAadharVisible: false,
                    };
                  } else {
                    return obj;
                  }
                });
                setBasicDetails(finalArr);
                toast.error(jsonResp.data?.response?.message);
                aadharField.readOnly = false;
              }
            } else {
              aadharField.readOnly = false;
              const finalArr = basicDetails.map((obj, index) => {
                if (index == id) {
                  return {
                    ...obj,
                    ca_aadhar_no: `XXXXXXXX`,
                    masked_ca_aadhar_no: `XXXXXXXX`,
                    isFetchAadharVisible: false,
                  };
                } else {
                  return obj;
                }
              });
              setBasicDetails(finalArr);
              toast.error("Internal server error");
            }
          } else {
            aadharField.readOnly = false;
            const finalArr = basicDetails.map((obj, index) => {
              if (index == id) {
                return {
                  ...obj,
                  ca_aadhar_no: "",
                  masked_ca_aadhar_no: "",
                  isFetchAadharVisible: false,
                };
              } else {
                return obj;
              }
            });
            setBasicDetails(finalArr);
            toast.error("Internal server error");
          }
        })
        .catch((error) => {
          console.error("ERROR : ", error.message);
        });
    } catch (error) {
      console.log(`ERROR : ${error.message}`);
      toast.error(error.message);
    }
  };

  const setAddress = (event) => {
    let customerData = leadDetail?.customer || {};
    let newArray = [...basicDetails];
    if (event.target.checked) {
      newArray[activetabIndex].ca_cra_pincode = customerData.cra_pincode;
      newArray[activetabIndex].ca_cra_state = customerData?.cra_state;
      newArray[activetabIndex].ca_cra_city = customerData?.cra_city;
      newArray[activetabIndex].ca_cra_address1 = customerData?.cra_address1;
      newArray[activetabIndex].ca_cra_address2 = customerData?.cra_address2;
      newArray[activetabIndex].same_as_cus_addr = "1";
    } else {
      newArray[activetabIndex].same_as_cus_addr = "0";
    }
    setBasicDetails(newArray);
  };

  const handleButtonClick = (data) => {
    let url = `/customer-detail/${crypto.encode(leadDetail?.id)}`;
    // history.push(url);
    setActiveTabFromChild("primary");
  };

  return (
    <>
      <div>
        <div className="customerflex">
          <h4 className="customerheading">Customer Details</h4>
          <br></br>
          {basicDetails.length && basicDetails.length < MAX_CO_APPLICANT && (
            <div className="coapplicantadd" onClick={addMoreFields}>
              <span>+</span> Add a Co-Applicant
            </div>
          )}
        </div>

        <div className="tab-list">
          <div className="commontabs">
            <div>
              <div className="partnername">Primary</div>
              <div
                className={
                  activeTab === "partner_name"
                    ? "commontabsItem active"
                    : "commontabsItem"
                }
              >
                <div
                  className="newtabContent"
                  onClick={() => handleButtonClick("Primary")}
                >
                  {leadDetail?.customer?.first_name}
                </div>
              </div>
            </div>

            {basicDetails.length &&
              basicDetails.map((coApplicant, index) => (
                <div>
                  <div className="coapplicantname">{`Co-Applicant - ${
                    index + 1
                  }`}</div>
                  <div
                    className={
                      activeTab === `coApplicant_${index}`
                        ? "commontabsItem active"
                        : "commontabsItem"
                    }
                    onClick={() => setActiveTab(`coApplicant_${index}`)}
                    eventkey={`coApplicant_${index}`}
                    active={activeTab === `coApplicant_${index}`}
                  >
                    <div className="newtabContent">
                      {" "}
                      {coApplicant?.ca_first_name}{" "}
                    </div>
                  </div>
                </div>
              ))}
          </div>
        </div>

        <Formsy
          className="lead-form-filed"
          autoComplete="off"
          onValid={() => setAllowSubmit(true)}
          onInvalid={() => setAllowSubmit(false)}
          onValidSubmit={handleSubmit}
        >
          <div className="row">
            {BasicDetailsForm.map(
              (field, index) =>
                basicDetails.length > 0 &&
                basicDetails.map(
                  (basicDetailData, index) =>
                    `coApplicant_${index}` === activeTab &&
                    (["text", "number"].includes(field.type) ? (
                      <fieldset className="form-filed col-md-6 " key={index}>
                        <FormsyInputField
                          id={field.id}
                          name={field.name}
                          type={field.type}
                          value={
                            basicDetailData &&
                            (field.name === "ca_mobile" ||
                              (field.name === "ca_aadhar_no" &&
                                basicDetailData.aadhar_touch))
                              ? basicDetailData[`masked_${field.name}`] || ""
                              : basicDetailData[field.name] || ""
                          }
                          placeholder=" "
                          thousandsGroupStyle="lakh"
                          thousandSeparator={","}
                          decimalSeparator={"."}
                          label={field.label}
                          maxLength={field.maxLength}
                          disabled={
                            [
                              "ca_cra_pincode",
                              "ca_cra_address1",
                              "ca_cra_address2",
                            ].includes(field.name) &&
                            basicDetailData.same_as_cus_addr == "1"
                              ? true
                              : false
                          }
                          onChange={handleInputChange}
                          format={field.ApplyNumberformat ? field.format : ""}
                          validations={
                            isFormTouch[field.name] ? field.validations : null
                          }
                          validationError={
                            basicDetailData[field.name] ||
                            basicDetailData[field.name] == ""
                              ? isFormTouch[field.name]
                                ? field.validationError
                                : ""
                              : ""
                          }
                          required={field.required}
                          readOnly={
                            field.name === "ca_aadhar_no" &&
                            basicDetailData.aadhar_touch &&
                            basicDetailData.ca_aadhar_no != null &&
                            basicDetailData.ca_aadhar_no != ""
                              ? true
                              : field.readOnly
                          }
                        />
                        {field.name === "ca_pancard_no" &&
                          basicDetailData.isFetchAadharVisible && (
                            <div
                              type="submit"
                              className="fetchAadharbtn"
                              onClick={handleFetchAadhar}
                              id={`${index}`}
                            >
                              Fetch Aadhar
                            </div>
                          )}
                      </fieldset>
                    ) : field.type === "date-picker" ? (
                      <fieldset className="form-filed col-md-6 " key={index}>
                        <FormsyDatePicker
                          name="dob"
                          selectedDate={
                            basicDetailData &&
                            basicDetailData[field.name] &&
                            new Date(basicDetailData[field.name])
                          }
                          placeholder={field.label}
                          maxDate={field.maxDate}
                          minDate={field.minDate}
                          value={
                            basicDetailData && basicDetailData[field.name]
                              ? dateFormat(
                                  basicDetailData[field.name],
                                  "dd mmm, yyyy"
                                )
                              : null
                          }
                          onDateSelect={(date) =>
                            handleDateChange(date, field.name)
                          }
                          dateFormat="dd MMM, yyyy"
                          validations={field.validations}
                          validationError={
                            basicDetailData[field.name]
                              ? field.validationError
                              : ""
                          }
                          required={field.required}
                        />
                      </fieldset>
                    ) : field.type === "dropdown" ? (
                      <fieldset className="single-select col-md-6" key={index}>
                        <FormsySelect
                          name={field.name}
                          id={field.id}
                          inputProps={{
                            options:
                              ALL_OPTIONS && ALL_OPTIONS[field.optionsKey],
                            placeholder: field.label,
                            className: "react-select",
                            classNamePrefix: "react-select",
                            value:
                              ALL_OPTIONS &&
                              ALL_OPTIONS[field.optionsKey] &&
                              ALL_OPTIONS[field.optionsKey].filter(
                                ({ value }) =>
                                  value == basicDetailData[field.name]
                              ),
                            isDisabled:
                              ["ca_cra_state", "ca_cra_city"].includes(
                                field.name
                              ) && basicDetailData.same_as_cus_addr == "1"
                                ? true
                                : false,
                          }}
                          required={field.required}
                          value={basicDetailData && basicDetailData[field.name]}
                          onChange={(data) =>
                            handleSelectChange(data, field.name)
                          }
                        />
                      </fieldset>
                    ) : field.type === "checkbox" ? (
                      <fieldset className="single-select col-md-12" key={index}>
                        <input
                          type="checkbox"
                          name={field.name}
                          value=""
                          onChange={(e) => setAddress(e)}
                          checked={
                            basicDetailData[field.name] == "1" ? true : false
                          }
                        />
                        <label> &nbsp;Same as customer address</label>
                      </fieldset>
                    ) : null)
                )
            )}
          </div>

          <div className="btn-save-remarks">
            <button type="submit" className="editnextbtn">
              Save
            </button>
          </div>
        </Formsy>
      </div>
    </>
  );
};
export default CoApplicantDetails;
